import React from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import {Field, reduxForm, getFormValues} from 'redux-form';
import * as validateRule from '../../validateRule';
import Grid from '@material-ui/core/Grid';
import {connect} from 'react-redux';

import LoginCommon, {commonStyles, renderTextField} from './loginCommon.js';
import PositiveButton from '../../atoms/Buttons/PositiveButton.js';
import {authOperations} from '../../../reducks/auth';
import {commonOperations} from '../../../reducks/common';
import {API_CODE, isChudenNwContract} from '../../../common/common';

import Conf from '../../../config/config.js';

// 利用可能時間
const availableTime = Conf.availableTime;

class Login extends React.Component {
  doLogin = async () => {
    console.log('#######');
    const {
      doShowMessage,
      getLoginInfo,
      history,
      loginAction,
      setIsTermsAccepted,
      setHasJuyoZiko,
      setIsKirikaeMae,
      signOut,
    } = this.props;

    try {
      const result = await loginAction();
      console.log('結果：' + result);
      if (result === 'OK') {
        // ログイン関連情報一括取得
        const loginInfo = await getLoginInfo();

        // エラーコードが指定されていて、成功ではない場合
        if (loginInfo.errorCode && (loginInfo.errorCode != API_CODE.SUCCESS)) {
          const errorData = loginInfo.data;
          switch (loginInfo.errorCode) {
            case API_CODE.ERROR_UNAVAILABLE_TIME:
              // 利用時間外の場合
              doShowMessage({
                message: {
                  id: 'CE0199',
                  values: [errorData.start, errorData.end],
                },
                action: () => {
                  signOut();
                },
              });
              break;

            default:
              // それ以外の場合はシステムエラー
              doShowMessage({
                message: 'CS0001',
                action: () => {
                  signOut();
                },
              });
              break;
          }

          return;
        }

        // ログイン日時がシステム切替日時以降なのか判別するフラグを設定する
        const _generalPurpose =
          loginInfo.generalPurpose != null ? loginInfo.generalPurpose : [];
        const listKirikaeDtList =
          _generalPurpose.filter(
              (data) => data.CategoryType__c == 'KirikaeDt' && data.Code__c == '1');
        const listKirikaeDt1 = listKirikaeDtList.length > 0 ?
          listKirikaeDtList[0].ValueDateTime__c : null;
        setIsKirikaeMae(loginInfo.systemDate, listKirikaeDt1);

        let _isTermsAccepted = loginInfo.isTermsAccepted;
        let _hasJuyoZiko = loginInfo.hasJuyoZiko;

        // 強制遷移を行うユーザかどうか判定する
        if (!this.checkForceTransition(loginInfo.currentUser)) {
          // メニューに遷移するようにフラグをたてる
          _isTermsAccepted = true;
          _hasJuyoZiko = false;
        }
        setIsTermsAccepted(_isTermsAccepted);
        setHasJuyoZiko(_hasJuyoZiko);

        // 約款が承諾されていない場合、重要事項説明画面へ遷移
        if (!_isTermsAccepted) {
          history.push('/importantSubjects', null, 'CLEAR');
          return;
        }

        // 未確認の重要事項確認が存在する場合、重要事項確認画面へ遷移
        if (_hasJuyoZiko) {
          history.push('/Other/ContactZiko/JuyoZiko/List', null, 'CLEAR');
          return;
        }

        // メニュー画面へ遷移
        history.push('/menu');
      } else if (result === 'REDIRECT_NEW_PASSWORD') {
        // 仮パスワードログインのため、パスワード変更画面へリダイレクト
        const dialog = {
          message: 'CI0123',
          action: () => {
            history.push('/changePassword');
          },
        };
        doShowMessage(dialog);
      } else if (result === 'NO_SF_USER') {
        // 本登録が完了していない
        const dialog = {
          message: 'CE0114',
        };
        doShowMessage(dialog);
      } else if (result === 'RESET_REQUIRE') {
        const dialog = {
          message: 'CI0126',
        };
        doShowMessage(dialog);
      } else if (result === 'NO_LOGIN') {
        const dialog = {
          message: 'CE0115',
        };
        doShowMessage(dialog);
      } else {
        const dialog = {
          message: 'CS0001',
        };
        doShowMessage(dialog);
      }
    } catch (err) {
      console.log('エラー');
      console.error(err);
      const dialog = {
        message: 'CS0001',
      };
      doShowMessage(dialog);
    }
  };

  /**
   * 画面の強制遷移を行うかどうかの判定。
   *
   * 重要事項説明が未承諾の場合は重要事項説明画面、
   * 未確認の重要事項が登録されている場合は重要事項確認画面へ
   * 遷移を行うが、以下いずれかに該当する場合は強制遷移を行わないと判定する
   * ・ログインユーザの契約区分が「中国電力ネットワーク契約」
   * ・ログインユーザが代行者
   *
   * @param {object} userInfo ユーザ情報
   * @return {boolean} 強制遷移を行う場合true
   */
  checkForceTransition = (userInfo) => {
    // 中国電力ネットワーク契約のユーザは強制遷移を行わない
    if (isChudenNwContract(userInfo)) {
      return false;
    }

    // 代行者の場合は強制遷移を行わない
    if (userInfo.RecordType.DeveloperName == 'Daikosya') {
      return false;
    }

    return true;
  }

  doPasswordReset = () => {
    this.props.history.push('/resetPassword');
  };

  moveToDocumentFormatList = () => {
    this.props.history.push('/templateList');
  };

  render() {
    const {classes, handleSubmit} = this.props;

    return (
      <LoginCommon>
        <Grid container>
          <Grid item xs={12}>
            <Field
              name="username"
              label="ユーザーID"
              className={classes.textField}
              required={true}
              margin="normal"
              variant="outlined"
              component={renderTextField}
              autoComplete="username"
              type="text"
              validate={[
                validateRule.required,
              ]}
            />
          </Grid>
          <Grid item xs={12}>
            <Field
              name="password"
              label="パスワード"
              type="password"
              className={classes.textField}
              required={true}
              margin="normal"
              variant="outlined"
              component={renderTextField}
              autoComplete="current-password"
              validate={[
                validateRule.required,
              ]}
            />
          </Grid>
          <Grid item xs={12}>
            <PositiveButton
              onClick={handleSubmit(this.doLogin)}
              variant="contained"
              className={classes.button}
              size="large"
            >
              <span>ログイン</span>
            </PositiveButton>
          </Grid>
          {/* <Grid item xs={12}>
            <Button
              onClick={this.moveToDocumentFormatList}
              className={classes.button}>
              <u>申込関連書類書式一覧</u>
            </Button>
          </Grid> */}
          <Grid item xs={12}>
            <Button
              onClick={this.doPasswordReset}
              className={classes.button}>
              <u>パスワードを忘れた方</u>
            </Button>
          </Grid>

          <div>
            {
              /*
                空のメッセージ表示エリア。
                align-items: flex-start;
                となっているため、相談窓口div位置保持のために残す。

                左側の通知メッセージを表示させる時は、本divを削除し
                下記のclassName=noticeAreaのdivのコメントを外す。
              */
            }
          </div>

          {/*
          <div className={classes.noticeArea}>
            <div className={classes.noticeTitle}>お知らせ</div>
            <span className={classes.noticeRed}>
              2024年2月3日（土）は終日メンテナンスのため、ユーポルのご利用ができません。<br/>
            </span>
            皆様には大変ご不便をおかけいたしますが、ご協力の程よろしくお願いいたします。<br/>
            ※なお、2024年2月4日（日）からは通常通りご利用いただける予定となっております。
          </div>
          */}

          { /* 期間経過後に削除する */}
          <div className={classes.helpDesk}>
          ◆債権譲渡先の合併および社名変更のご案内◆<br />
          これまで弊社が債権譲渡しておりました「ＳＭＢＣファイナンスサービス株式会社」は<br />
          2024年4月1日をもって三井住友カード株式会社と合併し、「三井住友カード株式会社」<br />
          へ社名変更いたします。インターネットバンキング等で銀行口座名義を「ＳＭＢＣファ<br />
          イナンスサービス株式会社」とご登録のお客さまは、お手数ですが、変更くださいます<br />
          ようお願いいたします。<br />
          </div>

          {availableTime &&
          <div className={classes.availableTimeArea}>
            <span className={classes.availableTime}>
            Yupol(ユーポル)稼働時間 : {availableTime.start} 〜 {availableTime.end}
            </span>
            <span className={classes.maintenanceExclusion}>
              ※システムメンテナンス時を除く
            </span>
          </div>
          }

          <span className={classes.copyright}>
            Copyright © Chugoku Electric Power Transmission & Distribution Co.,Inc. All rights reserved.
          </span>
        </Grid>
      </LoginCommon>
    );
  }
}

const FORM_NAME = 'Login';

Login.propTypes = {
  classes: PropTypes.object.isRequired,
  doShowMessage: PropTypes.func,
  handleSubmit: PropTypes.func,
  history: PropTypes.object,
  loginAction: PropTypes.func,
  getLoginInfo: PropTypes.func,
  setIsTermsAccepted: PropTypes.func,
  setHasJuyoZiko: PropTypes.func,
  setIsKirikaeMae: PropTypes.func,
  signOut: PropTypes.func,
};

const mapDispatchToProps = {
  getLoginInfo: commonOperations.doGetLoginInfo,
  setIsTermsAccepted: commonOperations.doSetIsTermsAccepted,
  setHasJuyoZiko: commonOperations.doSetHasJuyoZiko,
  setIsKirikaeMae: commonOperations.doSetIsKirikaeMae,
  signOut: authOperations.doSignOutOperation,
};

const mapStateToProps = (state) => ({
});

Login = reduxForm({
  form: FORM_NAME,
  destroyOnUnmount: false,
  enableReinitialize: true,
})(connect((state) => {
  return {
    values: getFormValues(FORM_NAME)(state),
  };
})(Login));
export default withStyles(commonStyles)(
    connect(
        mapStateToProps,
        mapDispatchToProps,
    )(Login),
);
